import { environment } from "../environments/environments.demo";

export const baseUrlHSI = environment.hsi.apiUrl + environment.hsi.apiRoot

export const HSI_HEADER = () => {
    return { 
        "Content-type": "application/json",
        "Authorization": environment.hsi.apiKey 
    }
};

export const HSI_HEADER_BEARER = () => {
    return { 
        "authorization": environment.hsi.apiKey 
    }
};

export const HSI_HEADER_API_KEY = () => {
    return { 
        "Content-type": "application/json",
        "api-key": environment.hsi.apiKey 
    }
};


export const HSI_GET_INSTITUTIONS = `${baseUrlHSI}/appointment/booking/institutionExtended`;
export const HSI_GET_SPECIALTY = `${baseUrlHSI}/appointment/booking/specialties`;
export const HSI_GET_MEDICAL_COVERAGE = `${baseUrlHSI}/appointment/booking/medicalCoverages`;

export const HSI_GET_AVAILABLE_APPOINTMENTS = (institution_id, specialty_id, coverage_id) => {
    return `${baseUrlHSI}/institution/${institution_id}/appointment/booking/specialty/${specialty_id}/practice/2/medicalCoverages/${coverage_id}/availability`;
};

export const HSI_POST_APPOINTMENT = (institution_id) => {
    return `${baseUrlHSI}/institution/${institution_id}/appointment/booking`;
};

export const HSI_CANCEL_APPOINTMENT = (institution_id, appointment_id) => {
    return `${baseUrlHSI}/institution/${institution_id}/appointment/${appointment_id}/cancel`;
};

export const HSI_GET_USER_APPOINTMENTS = (userData) => {
    return `${baseUrlHSI}/patient/${userData.dni}/appointments?identificationTypeId=${userData.identificationTypeId}&genderId=${userData.genderId}&birthDate=${userData.birthDate}`;
};

export const HSI_GET_INSTITUTION_PATIENT_ACTIVITIES = (institution_id, patient_dni, dateFrom, dateTo) => {
    return `${baseUrlHSI}/institution/refset/${institution_id}/patient/${patient_dni}/activities?reprocessing=false&from=${dateFrom}&to=${dateTo}`;
};

export const HSI_GET_PRESCRIPTIONS = (patient_dni) => {
    return `${baseUrlHSI}/patient/${patient_dni}/prescriptions`;
};
