import logoFondoBlanco from '../assets/statics/logo-fondo-blanco.jpg'

export const successRegister = {
  title: "Registro realizado",
  html: "Los datos del nuevo Paciente serán validados por un administrador antes de ser agregado al Grupo Familiar.",
  icon: "success",
  showCancelButton: false,
  confirmButtonText: "Continuar",
  confirmButtonColor: "#007bff",
};

export const errorActivePatient = {
  title: "Error al activar paciente",
  html: "Intente iniciar sesión nuevamente. ",
  icon: "error",
  showCancelButton: false,
  confirmButtonText: "Aceptar",
  confirmButtonColor: "#007bff",
};

export const logOut = {
  title: "¿Cerrar sesión?",
  icon: "warning",
  showCancelButton: true,
  cancelButtonText: "Cancelar",
  confirmButtonText: "Confirmar",
  confirmButtonColor: "#007bff",
  reverseButtons: true,
};

export const expiredSession = {
  title: "La sesión ha expirado",
  icon: "warning",
  showCancelButton: false,
  confirmButtonText: "Aceptar",
  confirmButtonColor: "#007bff",
};

export const error = (error) => {
  let content = {
    text: error,
    icon: "error",
    showConfirmButton: false,
    timer: 2000,
  };
  return content;
};

export const confirm = (text, is_success) => {
  let content = {
    title: text,
    icon: is_success ? "success" : "warning",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Confirmar",
    confirmButtonColor: "#007bff",
    reverseButtons: true,
  };
  return content;
};

export const success = (text) => {
  let content = {
    title: text,
    icon: "success",
    showCancelButton: false,
    showConfirmButton: false,
    timer: 2000,
  };
  return content;
};

export const warning = (text) => {
  let content = {
    text: text,
    icon: "warning",
    showConfirmButton: false,
    timer: 2000,
  };
  return content;
};

export const toastPatient = (text) => {
  let content = {
    position: "bottom-end",
    icon: "success",
    title: `Perfil de paciente ${text}`,
  };
  return content;
};


export const completeProfile = () => {
  let content = {
    title: '',
    html: `<image src="${logoFondoBlanco}" alt="Portal-del-paciente-la-rioja" width="400" /> 
      <h2>¡Te damos la Bienvenida!</h2>
    <p>Antes de continuar, por favor seleccioná un establecimiento de atención usual.</p>`,
    confirmButtonText: "Completar datos",
    confirmButtonColor: "#007bff",
    reverseButtons: true,
    allowOutsideClick: false,
    allowEscapeKey: false
  };
  return content;
};

export const errorNationalServices = {
  title: "Ocurrió un error ajeno a los servicios del Portal del Paciente",
  html: "Por favor, aguarde unos minutos e intente nuevamente",
  icon: "error",
  showCancelButton: true,
  confirmButtonText: "Aceptar",
  confirmButtonColor: "#007bff",
};

export const confirmAppointment = (appointmentData) => {
  let content = {
    title: '',
    html: ` 
      <h2>¿Querés reservar este turno?</h2>

        <div class="confirmAppointment">
          <p> <b>Nombre y Apellido:</b> ${appointmentData.nameAndSurname} </p>
          <p> <b>Email:</b> ${appointmentData.email}</p>
          <p> <b>Teléfono:</b> ${appointmentData.phoneNumber}</p>
          <p> <b>Día:</b> ${appointmentData.date}</p>
          <p> <b>Hora:</b> ${appointmentData.hour}</p>
          <p> <b>Profesional:</b> ${appointmentData.professional}</p>
          <p> <b>Institución:</b> ${appointmentData.institution} </p>
          <p> <b>Especialidad:</b> ${appointmentData.specialty}</p>
          <p> <b>Cobertura:</b> ${appointmentData.coverage}</p>
        </div>
        `,
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Sí, reservar",
    confirmButtonColor: "#007bff",
    reverseButtons: true,
    allowOutsideClick: false,
    allowEscapeKey: false
  };
  return content;
};  

