import { Button, Col, Row } from "react-bootstrap";
import * as MdIcon from 'react-icons/md'
import { environment } from "../../../../environments/environments.demo";

function PrescriptionCard({ prescriptionData }) {

    const doctor = prescriptionData.professionalData.lastName + ', ' + prescriptionData.professionalData.name
    const specialty = prescriptionData.professionalData.professions[0].profession
    const date = new Date(prescriptionData.prescriptionDate).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

    const handleDownload = () => {

        const link = document.createElement('a');
        link.href = environment.hsi.apiUrl + '/' + prescriptionData.link; // Reemplaza con el enlace del archivo
        link.download = 'nombre_del_archivo.pdf'; // Nombre con el que se descargará el archivo
        link.click();
    }

    return (
        <Row className={`appointment-card in 'bg-secondary-light`}>
            <Col xs={12}>
                <h5 className="mb-0">Profesional: {doctor}</h5>
                <p>Especialidad: {specialty}</p>
                <hr />
            </Col>
            <Col xs={12}>
                <span>Fecha: {date}</span>
            </Col>
            <Col xs={12} className="d-flex justify-content-end mt-2">
                <div className="my-tooltip" >
                    <Button variant="primary" onClick={() => { handleDownload() }}>
                        <MdIcon.MdDownload style={{ fontSize: '1.5rem' }} /> 
                        Descargar prescripción
                    </Button>
                </div> 
            </Col>
        </Row>
    )
}

export default PrescriptionCard;
