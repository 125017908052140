import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import usePatient from '../../hooks/usePatient';
import * as MdIcon from 'react-icons/md';
import { getInstitutionsHSI } from '../../services/applicactionService';
import { useForm } from 'react-hook-form';
import AutocompleteComponent from '../../components/AutocompleteComponent';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';
import { error } from '../../components/SwalAlertData';
import FormGroup from '../../components/RegisterForm/Forms/FormGroup';
import { getInstitutionPatientActivities } from '../../services/healthCertificate-hsi';
import MedicalCertificatePDF from './components/MedicalCertificatePDF';

const mokeIntitution = {
    "id": 14,
    "description": "HOSPITAL ZONAL Dr. EDUARDO S. NEIRA"
}

const mokeRequest = {
    institution: "10460422155034",
    patient_dni: '17544017',
    dateFrom: null,
    dateTo: null
}

const mokeData = [
    {
        "id": 6458,
        "encounterId": 1777,
        "attentionDate": {
            "year": 2024,
            "month": 8,
            "day": 30
        },
        "speciality": {
            "snomed": {
                "sctId": "394814009",
                "pt": "Medicina general y/o medicina de familia"
            }
        },
        "patient": {
            "identificationNumber": "17544017",
            "firstName": "MARISA",
            "lastName": "CACERES",
            "birthDate": {
                "year": 1965,
                "month": 5,
                "day": 24
            },
            "genderId": 1
        },
        "coverage": {
            "attentionCoverage": false,
            "cuitCoverage": "11111111"
        },
        "scope": "AMBULATORIA",
        "internmentInfo": {},
        "responsibleDoctor": {
            "id": 1,
            "firstName": "HUMBERTO",
            "lastName": "CIANCI",
            "licenceNumber": "111",
            "indentificationNumber": "11111111"
        },
        "diagnoses": {
            "main": {
                "sctId": "9414007",
                "pt": "trastorno de la tolerancia a la glucosa",
                "cie10Id": "R73.0"
            },
            "others": []
        },
        "attentionDateWithTime": {
            "date": {
                "year": 2024,
                "month": 8,
                "day": 30
            },
            "time": {
                "hours": 20,
                "minutes": 22,
                "seconds": 57
            }
        },
        "personExtendedInfo": {
            "middleNames": "ALEJANDRA"
        }
    },
    {
        "id": 6218,
        "encounterId": 1733,
        "attentionDate": {
            "year": 2024,
            "month": 7,
            "day": 11
        },
        "speciality": {
            "snomed": {
                "sctId": "394814009",
                "pt": "Medicina general y/o medicina de familia"
            }
        },
        "patient": {
            "identificationNumber": "17544017",
            "firstName": "MARISA",
            "lastName": "CACERES",
            "birthDate": {
                "year": 1965,
                "month": 5,
                "day": 24
            },
            "genderId": 1
        },
        "coverage": {
            "attentionCoverage": false
        },
        "scope": "AMBULATORIA",
        "internmentInfo": {},
        "responsibleDoctor": {
            "id": 1,
            "firstName": "HUMBERTO",
            "lastName": "CIANCI",
            "licenceNumber": "111",
            "indentificationNumber": "11111111"
        },
        "diagnoses": {
            "main": {
                "sctId": "405737000",
                "pt": "faringitis",
                "cie10Id": "J02.9"
            },
            "others": []
        },
        "attentionDateWithTime": {
            "date": {
                "year": 2024,
                "month": 7,
                "day": 11
            },
            "time": {
                "hours": 11,
                "minutes": 12,
                "seconds": 6
            }
        },
        "personExtendedInfo": {
            "middleNames": "ALEJANDRA"
        }
    },
    {
        "id": 308,
        "encounterId": 80,
        "attentionDate": {
            "year": 2022,
            "month": 3,
            "day": 10
        },
        "speciality": {
            "snomed": {
                "sctId": "320591000221104",
                "pt": "Clínica médica"
            }
        },
        "patient": {
            "identificationNumber": "17544017",
            "firstName": "MARISA",
            "lastName": "CACERES",
            "birthDate": {
                "year": 1965,
                "month": 5,
                "day": 24
            },
            "genderId": 1
        },
        "coverage": {
            "attentionCoverage": false
        },
        "scope": "AMBULATORIA",
        "internmentInfo": {},
        "responsibleDoctor": {
            "id": 1,
            "firstName": "HUMBERTO",
            "lastName": "CIANCI",
            "licenceNumber": "111",
            "indentificationNumber": "11111111"
        },
        "diagnoses": {
            "main": {
                "sctId": "15574005",
                "pt": "fractura de pie",
                "cie10Id": "S92.90"
            },
            "others": []
        },
        "attentionDateWithTime": {
            "date": {
                "year": 2022,
                "month": 3,
                "day": 10
            },
            "time": {
                "hours": 10,
                "minutes": 50,
                "seconds": 31
            }
        },
        "personExtendedInfo": {
            "middleNames": "ALEJANDRA"
        }
    }
]

const HealthCertificate = () => {

    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(false)

    const p = usePatient();
    const [institutions, setInstitutions] = useState([]);
    const [selectedInst, setSelectedInst] = useState(null);
    const { register, setValue, handleSubmit, formState: { errors, isValid } } = useForm();
    const [data, setData] = useState([]);


    const [valuesForm, setValuesForm] = useState({
        institution: "",
        patient_dni: p.patient.identification_number,
        dateFrom: null,
        dateTo: null,
    })

    const getInstitutions = useCallback(
        () => {
            getInstitutionsHSI()
                .then((res) => {
                    setInstitutions(res);
                    return institutions
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire(error('Error al cargar centros médicos'))
                })
                .finally(() => setLoading(false));
        },
        [institutions],
    )

    useEffect(() => {
        getInstitutions()
    }, [])


    const handleChange = (date, key) => {
        if (date) {
            setValuesForm({
                ...valuesForm,
                [key]: date,
            });
            setValue(key, date)
        }
    }

    const handleSearchInstitution = (value) => {
        if (typeof value !== 'string' && value.id) {
            let selected = institutions.find((item) => {
                return item.description.toLowerCase().trim() === value.name.toLowerCase()
            }).sisaCode
            setSelectedInst(selected)
            setValuesForm({ ...valuesForm, institution: selected })
            setValue('institution', selected)
        }
    }

    const onSubmit = () => {
        setLoadingData(true)
        const inst = valuesForm.institution
        const patient = valuesForm.patient_dni
        const from = valuesForm.dateFrom.toISOString().split('T')[0];
        const to = valuesForm.dateTo.toISOString().split('T')[0];
        getHealthCertficate(inst, patient, from, to)
    }

    const getHealthCertficate = useCallback(
        (institution, patient_dni, dateFrom, dateTo) => {

            getInstitutionPatientActivities(institution, patient_dni, dateFrom, dateTo)
                .then((res) => {
                    setLoadingData(false)
                    setData(res)
                })
                .catch((err) => {
                    console.error(err)
                    Swal.fire(error('Error al obtener información'))
                })
        }, [])



    return (
        <Container className='p-3'>
            <Col xs={12} lg={9} className="d-flex">
                <MdIcon.MdOutlineFactCheck className="menu-icon text-danger me-1" style={{ fontSize: 'x-large' }} />
                <h5 className='section-title'>Certificado de Salud</h5>
            </Col>
            <Row>
                <p className='pt-3'>Completá el formulario para generar tu Certificado de Control de Salud</p>
                {loading ? <Loader isActive={loading} /> :
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Col xs={12} lg={6}>
                            <Form.Group className="mt-4">
                                <Form.Label>Centro Médico</Form.Label>
                                <AutocompleteComponent
                                    variants={institutions}
                                    handleChange={handleSearchInstitution}
                                    className='position-sticky'
                                // {...register('institution', {
                                //     required: {
                                //         value: true,
                                //         message: "El campo es requerido."
                                //     }
                                // })}
                                />
                                {errors.institution && <ErrorMessage><p>{errors.institution.message}</p></ErrorMessage>}
                            </Form.Group>
                        </Col>
                        <Col className="mt-4 mb-2" xs={12} lg={3}>
                            <FormGroup inputType='datePicker' label='Desde (Seleccionar fecha)' name='dateFrom'
                                selectValue={valuesForm.dateFrom}
                                minDate={new Date('2000-01-01')}
                                maxDate={new Date()}
                                {...register('dateFrom', {
                                    required: {
                                        value: true,
                                        message: "El campo es requerido."
                                    }
                                })}
                                handleChange={(date) => handleChange(date, 'dateFrom')}
                            />
                            {errors.dateFrom && <ErrorMessage><p>{errors.dateFrom.message}</p></ErrorMessage>}
                        </Col>
                        <Col className="mt-4 mb-2" xs={12} lg={3}>
                            <FormGroup inputType='datePicker' label='Hasta (Seleccionar fecha)' name='dateTo'
                                selectValue={valuesForm.dateTo}
                                minDate={valuesForm.dateFrom}
                                maxDate={new Date()}
                                {...register('dateTo', {
                                    required: {
                                        value: true,
                                        message: "El campo es requerido."
                                    }
                                })}
                                handleChange={(date) => handleChange(date, 'dateTo')}
                            />
                            {errors.dateTo && <ErrorMessage><p>{errors.dateTo.message}</p></ErrorMessage>}
                        </Col>
                        {!loadingData ?
                            <div>
                                <Col xs={12} lg={6} className='mt-4 d-flex justify-content-end'>
                                    <Button variant='danger' className="me-2" type="submit"><MdIcon.MdOutlineFactCheck className='me-2' />Generar certificado</Button>
                                </Col>
                                {data.length > 0 && <MedicalCertificatePDF appointments={data} institution={selectedInst} to={valuesForm.dateTo} from={valuesForm.dateFrom} />}
                            </div>
                            :
                            <Loader isActive={loadingData}></Loader>}
                    </Form>

                }
            </Row>
        </Container>
    )
}

export default HealthCertificate;
