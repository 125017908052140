import { Col, Container } from "react-bootstrap";
import DataNotFound from "../../components/DataNotFound";
import FilesDragAndDrop from "./DragAndDrop/drag_and_drop";
import * as MdIcon from 'react-icons/md';

export default function Estudios() {
        
    return (
        <Container className='estudios p-3'>
              <Col xs={12} lg={9} className="d-flex">
                <MdIcon.MdTextSnippet className="menu-icon text-danger me-1" style={{ fontSize: 'x-large' }} />
                <h5 className='section-title'>Estudios</h5>
            </Col>
            <FilesDragAndDrop />
        </Container>
        
        
    )
}
