import React, { useState } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { Button, Col } from 'react-bootstrap';
import * as MdIcon from 'react-icons/md';


const MedicalCertificatePDF = ({ appointments, institution, to, from}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const generatePDF = async () => {
    setIsGenerating(true);

    try {
      const pdfDoc = await PDFDocument.create();
      
      // Descargar y cargar la fuente Roboto de Google Fonts
      const robotoFontUrl = 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff2';
      const robotoFontBytes = await fetch(robotoFontUrl).then(res => res.arrayBuffer());
      const robotoFont = await pdfDoc.embedFont(robotoFontBytes);

      const page = pdfDoc.addPage();
      const { width, height } = page.getSize();
      const fontSize = 10;

      page.drawText('PORTAL DEL PACIENTE | LA RIOJA', {
        x: 50,
        y: height - 30,
        size: 14,
        font: robotoFont,
        color: rgb(233/255, 0/255, 45/255), // #E9002D en formato RGB
      });

      page.drawText(`Certificado de Atención Médica`, {
        x: 50,
        y: height - 80,
        size: 16,
        color: rgb(0, 0, 0),
      });

      page.drawText(`${institution.description}`, {
        x: 50,
        y: height - 100,
        size: 16,
        color: rgb(0, 0, 0),
      });
      

      // Ordenamos los turnos del más nuevo al más viejo
      const sortedAppointments = [...appointments].sort((a, b) => {
        const dateA = new Date(a.attentionDate.year, a.attentionDate.month - 1, a.attentionDate.day);
        const dateB = new Date(b.attentionDate.year, b.attentionDate.month - 1, b.attentionDate.day);
        return dateB.getTime() - dateA.getTime();
      });

      let yOffset = height - 120;

      sortedAppointments.forEach((appointment, index) => {
        const appointmentInfo = [
          `Fecha de atención: ${appointment.attentionDate.day}/${appointment.attentionDate.month}/${appointment.attentionDate.year}`,
          `Especialidad: ${appointment.speciality.snomed.pt}`,
          `Paciente: ${appointment.patient.lastName}, ${appointment.patient.firstName}`,
          `DNI: ${appointment.patient.identificationNumber}`,
          `Fecha de nacimiento: ${appointment.patient.birthDate.day}/${appointment.patient.birthDate.month}/${appointment.patient.birthDate.year}`,
          `Médico: ${appointment.responsibleDoctor.lastName}, ${appointment.responsibleDoctor.firstName}`,
          `Matrícula: ${appointment.responsibleDoctor.licenceNumber}`,
          `Diagnóstico: ${appointment.diagnoses.main.pt}`,
          `Hora de atención: ${appointment.attentionDateWithTime.time.hours}:${appointment.attentionDateWithTime.time.minutes.toString().padStart(2, '0')}`,
          '',
          '___________________________________________________________'
        ];

        appointmentInfo.forEach((line, lineIndex) => {
          page.drawText(line, {
            x: 50,
            y: yOffset - (index * 4 + lineIndex) * fontSize,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
        });

        yOffset -= 9 * fontSize;

        if (yOffset < 50) {
          page = pdfDoc.addPage();
          yOffset = height - 50;
        }
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'certificado_medico.pdf';
      link.click();
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    } finally {
      setIsGenerating(false);
    }
  };  
  return (
    <Col xs={12} lg={6} className="border boder-secundary rounded p-4 mt-4 mb-2">

      {/* <Col className=''> */}
        <p>Certificado Médico: {institution?.description} </p>
        {from && to && <p>{from.toLocaleDateString()} - {to.toLocaleDateString()}</p>}
      {/* </Col> */}

      <Col xs={12} className='d-flex justify-content-end'>
        <Button
          type="button"
          variant="primary"
          onClick={generatePDF}
          disabled={isGenerating}
          className="btn"
          aria-busy={isGenerating}
        >
          <MdIcon.MdDownload className='me-2'></MdIcon.MdDownload>
          {isGenerating ? 'Generando PDF...' : 'Descargar Certificado Médico'}
        </Button>
      </Col>
    </Col>
  );
};

export default MedicalCertificatePDF