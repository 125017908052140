import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import usePatient from '../../hooks/usePatient';
import * as MdIcon from 'react-icons/md';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';
import { error } from '../../components/SwalAlertData';
import DataNotFound from '../../components/DataNotFound';
import { getPrescriptions } from '../../services/healthCertificate-hsi';
import PrescriptionCard from './components/PrescriptionCard/PrescriptionCard';

const Medication = () => {

    const [loading, setLoading] = useState(true)

    const p = usePatient();

    const [data, setData] = useState([]);


    const getDataPrescriptions = useCallback(() => {
        getPrescriptions(p.patient.identification_number)
            .then((res) => {
                setLoading(false)
                setData(res)
            })
            .catch((err) => {
                console.error(err)
                Swal.fire(error('Error al obtener información'))
            })
    }, [])

    useEffect(() => {
        getDataPrescriptions();
    }, [])


    return (
        <Container className='p-3'>
            <Col xs={12} lg={9} className="d-flex">
                <MdIcon.MdOutlineMedication className="menu-icon text-danger me-1" style={{ fontSize: 'x-large' }} />
                <h5 className='section-title'>Prescripción de medicamentos</h5>
            </Col>
            <Row>
                {loading ? <Loader isActive={loading} /> :

                    <>
                        {data && data.length > 0 ?
                            <>
                                {data.map((d, i) => {
                                    return <Col xs={12} lg={6} key={i}>
                                        <PrescriptionCard prescriptionData={d}></PrescriptionCard>
                                    </Col>

                                })}
                            </>
                            :
                            <DataNotFound text="prescripciones de medicamentos" ></DataNotFound>
                        }
                    </>
                }
            </Row>
        </Container>
    )
}

export default Medication;
